import { graphql } from "gatsby"
import TagComponent from "../components/tag"

export default TagComponent

export const query = graphql`
  query($slug: String!, $formatString: String!) {
    allMdx(filter: {childrenMdxPost: {elemMatch: {slug: {eq: $slug}}}}) {
      nodes {
        body
        childrenMdxPost {
          slug
        }
        excerpt
        timeToRead
        frontmatter {
          date(formatString: $formatString)
          title
          banner {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tags
          recipe {
            title
            ingredients {
              name
              percentage
            }
          }
        }
      }
    }
  }
`